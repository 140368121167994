import React from 'react';

import { Image, CardContainer, Title, Description } from './styles';
interface Props { 
  bgColor?: string;
  description?: string;
  title?: string;
  textColor?: string;
  imgUrl?: string;
}

const Card: React.FC <Props>= ({bgColor,description, title, imgUrl, textColor}) => {
  return (<CardContainer bgColor={bgColor}>
    <Title textColor={textColor}>{title}</Title>
    <Description textColor={textColor}>{description}</Description>
    <Image src={imgUrl}></Image>
  </CardContainer>)
  ;
}

export default Card;