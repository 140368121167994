import React from 'react';

import './App.css'
import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';



function App() {
  return (    
     <>
     <Header/>
     <Main/>
     <Footer/>
     </>
    
    
  );
}

export default App;
