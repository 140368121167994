import React from 'react';

import { Container, Image, ContainerImg } from './styles';
import facebook from '../../../assets/facebook.png'
import instagram from '../../../assets/instagram.png'
import twitter from '../../../assets/twitter.png'
import whatsapp from '../../../assets/whatsapp.png'
import youtube from '../../../assets/youtube.png'
import telegram from '../../../assets/telegram.png'


const SocialMedia: React.FC = () => {
  return (
   <Container>
    <ContainerImg>
      Nossas redes sociais
    </ContainerImg>
    
    <ContainerImg>
    <Image src={facebook}></Image>
    <Image src={instagram}></Image>
    <Image src={twitter}></Image>
    <Image src={whatsapp}></Image>
    <Image src={youtube}></Image>
    <Image src={telegram}></Image>
    </ContainerImg>
    
   </Container>
  );
}

export default SocialMedia;