import React from 'react';

import { Container } from './styles';

interface Props {
    children: React.ReactNode;
    backgroundColor?: string;
    textColor?: string;
  }

const DeckCard: React.FC<Props> = ({children, backgroundColor, textColor}) => {
  return (
    <Container backgroundColor={backgroundColor} textColor={textColor}>  
           {children}  
    </Container>
  );
}

export default DeckCard;