import styled from 'styled-components';

interface Props {
  children: React.ReactNode;
  backgroundColor?: string;
  textColor?: string;
}

export const Container = styled.div<Props>`
display: flex;
flex-direction: row;
align-items:center;
justify-content: space-between;
height: 400px;
width: 100%;
margin-bottom: 10px;
margin-left: 20px;
margin-right: 20px;
background-color: ${(props: { backgroundColor: any; }) => props.backgroundColor || '#003F5D'};


@media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 5px;
  }

`;

export const H1 = styled.h1`
font-size: 30;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
color: #fff;
font-weight: bold;
`;