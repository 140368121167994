import React from 'react';
import DeckCard from './DeckCard';
import Card from './Card';

import { Container, H1 } from './styles';



const Main: React.FC = () => {
  return (
    <Container> 
      <H1>Desafios</H1>      
      <DeckCard>      
      <Card
      bgColor='#c3eebd'
      title='TESTE'
      imgUrl='https://rogeriobastos.dev.br/static/media/facebook.892b3f33.png'
      description='Aqui vai um texto descritivo sobre o problema'/>  
      <Card
      bgColor='#c3eebd'
      title='TESTE2'
      imgUrl='https://rogeriobastos.dev.br/static/media/facebook.892b3f33.png'
      description='Aqui vai um texto descritivo sobre o problema'/>  
      <Card
      bgColor='#c3eebd'
      title='TESTE3'
      imgUrl='https://rogeriobastos.dev.br/static/media/facebook.892b3f33.png'
      description='Aqui vai um texto descritivo sobre o problema'/>     
       <Card
      bgColor='#c3eebd'
      title='TESTE3'
      imgUrl='https://res.cloudinary.com/dqbqmkbmf/image/upload/v1678985765/sample.jpg'
      description='Aqui vai um texto descritivo sobre o problema'/>  
      </DeckCard>
      <DeckCard backgroundColor='#e9ffe6' textColor='#000000'>
      <H1 textColor='#000000'>Soluçoes</H1>
      </DeckCard>
      <DeckCard backgroundColor='#cdf3c8' textColor='#e21313'>
      <H1 textColor='#e21313'>Resultados</H1>
      </DeckCard>
    </Container>
    
  );
}

export default Main;