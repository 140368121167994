import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: row;
align-items:center;
justify-content: space-between;
height: 100px;
width: auto;
background-color: #efc30e;
padding-right: 20px;
padding-left: 20px;

@media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 5px;
  }

`;

export const H1 = styled.h1`
font-size: 30;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
color: #fff;
font-weight: bold;
`;