import styled from 'styled-components';

interface Props {  
  backgroundColor?: string;
  textColor?: string;
}

export const Container = styled.div`
display: flex;
flex-direction: column;
align-items:center;
height: auto;
min-height: 600px;
width: auto;
background-color: #C0D1C2;
padding: 20px;


@media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 5px;
  }

`;

export const H1 = styled.h1<Props>`
font-size: 30;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
color: ${(props: { textColor: any; }) => props.textColor || 'white'};
font-weight: bold;
`;