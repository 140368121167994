import styled from 'styled-components';

export const Container = styled.div`
width: auto;
display: flex;
flex-direction: column;
align-items:center;
height: 80px;
justify-content:center;
color: #fff;
font-weight:bold;
`;
export const ContainerImg = styled.div`
height: 30px;
display: flex;
flex-direction:row;
width: 100%;
justify-content:center;

`;

export const Image = styled.img `
  width: 30px;  
  height: 30px;
  padding: 5px;
`;
