import React from 'react';
import Logo from './Logo';
import SocialMedia from './SocialMedia';

import { Container, H1 } from './styles';

const Header: React.FC = () => {
  return (
    <Container>
      <Logo/>
      <div ><H1>INTEGRAÇÃO CONTINUA</H1></div>
      <SocialMedia/>
    </Container>
  );
}

export default Header;