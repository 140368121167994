import styled from 'styled-components';



export const CardContainer = styled.div`
  background-color: ${(props: { bgColor: any; }) => props.bgColor || '#fff'};
  display: flex;
  flex-direction: column;
  align-items:center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 16px;
  width: 300px;
`;

export const Title = styled.h2`
  color: ${(props: { textColor: any; }) => props.textColor || '#333'};
  font-size: 24px;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  color: ${(props: { textColor: any; }) => props.textColor || '#666'};
  font-size: 16px;
`;


export const Image = styled.img `
  width: 100px;  
  height: 100px;
  padding: 5px;
`;
