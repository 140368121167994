import React from 'react';

// import { Container } from './styles';

const Footer: React.FC = () => {
  return (
  <p>Footer</p>
    );
}

export default Footer;