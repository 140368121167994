import React from 'react';

import { Image } from './styles';
import logo from '../../../assets/logo.png'

const Logo: React.FC = () => {
  return (
    <Image src={logo}/>
  );
}

export default Logo;